
import Vue from "vue";
import SectionsList from "@/components/subviews/sections/sections-list.vue";
import SectionsMaintenance from "@/components/subviews/sections/sections-maintenance.vue";
import ISection from "@/lib/interfaces/section";

export default Vue.extend({
  name: "Sections",

  components: { SectionsList, SectionsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      section: {} as ISection,
    };
  },
});
