
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import ISection from "@/lib/interfaces/section";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "SectionsList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    section: {
      type: Object as PropType<ISection>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      sections: new Array<ISection>(),

      headers: [
        { text: "Nome", value: "cNome", sortable: false },
        { text: "Domínio", value: "dominio" },
        { text: "Banco de autenticação", value: "auth_database" },
        { text: "Banco do setor", value: "nome_database" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getSections().then();
    },

    options: {
      deep: true,
      handler() {
        this.getSections().then();
      },
    },
  },

  computed: {
    cSections(): Array<Record<string, unknown>> {
      return this.sections.map((section) => ({
        ...section,
        cNome: `${section.id} - ${section.nome}`,
        cDesativado: section.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openSection(id: string) {
      const section = this.sections.find((section) => section.id === id);
      this.$emit("update:section", { ...section });
    },

    async getSections() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("section.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.sections = rsp.getTable("setor")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
