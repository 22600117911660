
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import ISection from "@/lib/interfaces/section";
import { required, maxLength } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";

export default Vue.extend({
  name: "SectionMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ACard,
  },

  props: {
    section: {
      type: Object as PropType<ISection>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataSection: {
        id: "",
        nome: "",
        nome_database: "",
        desativado: "0",
        auth_database: "",
        plataforma_id: "0",
        dominio: "",
      },
    };
  },

  watch: {
    section: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataSection = { ...this.section };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataSection.id ? "Alterar" : "Salvar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  methods: {
    required,
    maxLength,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("section.write");

        const tblSetor = new Table("setor");

        const obj = {
          ...this.dataSection,
          id: this.dataSection.id || "default",
        };
        tblSetor.setColsFromObject(obj);
        tblSetor.addRowsFromObject(obj);

        rq.addTable(tblSetor);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um setor com esse nome";

          return;
        }

        this.ret_msg = `Setor ${
          this.dataSection.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataSection.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.form.resetValidation();
        this.loading = false;
      }
    },

    clearForm() {
      this.dataSection = {
        id: "",
        nome: "",
        nome_database: "",
        desativado: "0",
        auth_database: "",
        plataforma_id: "0",
        dominio: "",
      };

      this.form.resetValidation();
    },
  },
});
